<div class="d-inline-flex w-100 justify-content-evenly bg-image p-5 text-white text-center image">
  <div class="align-self-center">
    <span class="h1 display-4 mb-5">
      Find Your Portal
    </span>
    <p class="mt-4 fs-4">
      Read the information below to login to the portal that meets your needs
    </p>
  </div>
</div>
<div class="container button-container">
  <div class="justify-content-center align-items-center gy-2 gy-md-0 row row-cols-2 row-cols-md-{{columns}}">
      <mecca-menu-buttons *ngIf="environment.showLicensure" [portal]="portals.licensure"></mecca-menu-buttons>
      <mecca-menu-buttons *ngIf="environment.showObservation" [portal]="portals.observations"></mecca-menu-buttons>
      <mecca-menu-buttons *ngIf="environment.showJobs" [portal]="portals.jobs"></mecca-menu-buttons>
      <mecca-menu-buttons *ngIf="environment.showAccreditation" [portal]="portals.accreditation"></mecca-menu-buttons>
  </div>
</div>
