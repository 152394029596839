<div *ngIf="enabled" class="container p-5">
  <div class="row row-cols-1 row-cols-md-2 g-5">
    <div class="col col-md-5">
      <h1 id="{{portal.id}}" class="text-primary">{{portal.title}}</h1>
    </div>
    <div class="col col-md-5 text-gray" *ngIf="portal.showDetails">
      <ng-container *ngIf="portal.details.description">
        <p class="fw-bold">{{portal.details.description}}</p>
      </ng-container>
      <ul>
        <li class="fw-bold" *ngFor="let item of portal.details.items">
          <ng-container *ngIf="item.isUrl; else text">
          <a href="{{item.value}}">{{item.urlText ?? item.value}}</a>
        </ng-container>
        <ng-template #text >
          {{item.value}}
        </ng-template>
        </li>
      </ul>
    </div>
    <div *ngFor="let login of portal.logins" class="col col-md-5">
      <p *ngIf="login.heading" class="text-primary fs-3 fw-bold">{{login.heading}}</p>
      <p *ngIf="login.subheading" class="text-gray fw-bold">
        {{login.subheading}}
      </p>
      <div class="my-3">
        <a class="login-button btn btn-lg btn-secondary text-white text-uppercase" [href]="login.loginUrl">{{login.loginButtonText}}</a>
      </div>
      <p *ngIf="login.showRegistrationButton">
        <a class="text-secondary fw-bold" [href]="login.registrationUrl">{{login.registrationText}}</a>
      </p>
    </div>
  </div>
</div>


