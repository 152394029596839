<a class="btn btn-secondary text-white menu-button fs-6 fw-semibold d-none d-xl-grid mx-auto xl-btn-width" role="button" href="#{{portal.id}}">
  <span class="align-self-center">
    {{portal.title}}
  </span>
</a>
<a class="btn btn-secondary text-white menu-button fs-7 fw-semibold d-none d-lg-grid d-xl-none mx-auto xl-btn-width" role="button" href="#{{portal.id}}">
  <span class="align-self-center">
    {{portal.title}}
  </span>
</a>
<a class="btn btn-secondary text-white menu-button fw-semibold fs-8 d-grid d-lg-none mx-auto xl-btn-width" role="button" href="#{{portal.id}}">
  <span class="align-self-center">
    {{portal.title}}
  </span>
</a>
