<nav class="navbar bg-white">
  <div class="container-fluid px-5">
    <a class="navbar-brand" href="#"><img src="./assets/ms-logo.png" class="navbar-logo" alt="Mississippi Department of Education Logo"></a>
    <span *ngIf="environment.showEnvironmentName" class="navbar-text text-danger fs-1 fw-bold text-uppercase">
      {{environment.name}}
    </span>
    <span class="navbar-text text-lightgray">
      Mississippi Educator Career Continuum Archive
    </span>
  </div>
</nav>
