import { Component, Input, OnInit } from '@angular/core';
import { IPortal } from '@interfaces/IPortal';

@Component({
  selector: 'mecca-menu-buttons',
  templateUrl: './menu-buttons.component.html',
  styleUrls: ['./menu-buttons.component.scss']
})
export class MenuButtonsComponent implements OnInit {

  @Input()
  portal!: IPortal;
  constructor() { }

  ngOnInit(): void {
  }

}
