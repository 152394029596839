import { Component } from '@angular/core';
import { environment } from '@environments/environment';
import { portals } from '@portals/portals';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'MISSISSIPPI EDUCATOR CAREER CONTINUUM ARCHIVE';
  environment = environment;
  portals = portals;

}
