<footer class="d-flex flex-wrap justify-content-md-between justify-content-evenly p-5 bg-primary">
  <div class="col-auto mb-5 mb-md-0  justify-content-start text-white fw-semibold lh-sm footer-font">
    <p class="m-0">
      Mississippi Department of Education
    </p>
    <p class="m-0">
      P.O. Box 771
    </p>
    <p class="m-0">
      Jackson, MS 39205-0771
    </p>
    <p class="m-0">
      Educator Licensure Related Information: <a class="text-white" href="tel:+16013593483">601-359-3483</a>
    </p>
    <p class="m-0">
      General MDE Related Information: <a class="text-white" href="tel:+16013593513">601-359-3513</a>
    </p>
  </div>
  <div class="col-auto col-md-4 justify-content-end text-white text-end">
    <a href="https://ms.gov" target="_blank"><img src="./assets/ms-official-gov.png" alt="Mississippi's Official State Website" class="mecca-footer-logo"></a>
  </div>
</footer>
