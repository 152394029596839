import { Component, Input, OnInit } from '@angular/core';
import { IPortal } from '@interfaces/IPortal';

@Component({
  selector: 'mecca-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit {

  @Input()
  enabled!: boolean;
  @Input()
  portal!: IPortal;
  constructor() { }

  ngOnInit(): void {
  }

}
