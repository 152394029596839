<div class="container">
  <mecca-header></mecca-header>
  <mecca-menu></mecca-menu>
  <div class="mb-5"></div>
  <mecca-portal [enabled]="environment.showLicensure" [portal]="portals.licensure"></mecca-portal>
  <hr class="mecca-divider" *ngIf="environment.showLicensure && (environment.showObservation || environment.showJobs || environment.showAccreditation)" />
  <mecca-portal [enabled]="environment.showObservation" [portal]="portals.observations"></mecca-portal>
  <hr class="mecca-divider" *ngIf="environment.showObservation && (environment.showJobs || environment.showAccreditation)" />
  <mecca-portal [enabled]="environment.showJobs" [portal]="portals.jobs"></mecca-portal>
  <hr class="mecca-divider" *ngIf="environment.showJobs && environment.showAccreditation" />
  <mecca-portal [enabled]="environment.showAccreditation" [portal]="portals.accreditation"></mecca-portal>
  <mecca-footer></mecca-footer>
</div>
