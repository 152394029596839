import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { portals } from '@portals/portals';

@Component({
  selector: 'mecca-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  columns = 0;
  environment = environment;
  portals = portals;

  constructor() { }

  ngOnInit(): void {
    if (environment.showAccreditation)
      this.columns++;
    if (environment.showJobs)
      this.columns++;
    if (environment.showLicensure)
      this.columns++;
    if (environment.showObservation)
      this.columns++;
  }

}
